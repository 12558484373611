import React from "react";
import { FormType, useOpenDrawerForm } from "../../store/model-drawer-form";
import { useStoreActions } from "../../hooks/ep";
import { MenuItem } from "@blueprintjs/core";
import { parseEmailSenderToPayload } from "../common/parse-object-values";
import { EditEmailSender_FormContent } from "./edit-email-sender-form-content";

export function MenuItem_EditEmailSender({ data }): JSX.Element {
  const openDrawer = useOpenDrawerForm();
  const handleSubmit = useStoreActions(
    (a) => a.emailSenders.handleEditEmailSender
  );

  const onClick = () => {
    const title = "Please edit the Email Sender's details";
    const initialData = {
      fieldName2Value: parseEmailSenderToPayload(data),
    };

    const ContentComponent = EditEmailSender_FormContent;
    openDrawer({
      initialData,
      title,
      ContentComponent,
      handleSubmit,
      formType: FormType.EditEmailSender,
    });
  };

  return <MenuItem icon="edit" text="Edit" onClick={onClick} />;
}
