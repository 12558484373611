import { submitUserActionPostRequest } from "../../user-actions/user_actions";

export async function declareEmailSender(action_payload: EmailSender) {
  return await submitUserActionPostRequest(
    "declare_email_sender",
    action_payload
  );
}

export interface EmailSender {
  first_name: string;
  last_name: string;
  email_address: string;
  title: string;
  phone_number: string;
  active: boolean;
  uuid?: string;
}
