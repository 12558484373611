export default {
  apiKey: "AIzaSyDz5NtYIBj5UH2ihsVUQ4MUtWna4KNG8ck",
  authDomain: "neon-cmax.firebaseapp.com",
  projectId: "neon-cmax",
  storageBucket: "neon-cmax.appspot.com",
  messagingSenderId: "632821410940",
  appId: "1:632821410940:web:9af178aa6141fefc8badf9",
  measurementId: "G-2W47PSJ93T",
  //
  databaseURL: "https://neon-cmax.firebaseio.com",
};
