import React from "react";
import { Button, Icon, Intent, Tooltip } from "@blueprintjs/core";

export function CloseButton({ tooltipMessage, onClose }): JSX.Element {
  return (
    <Tooltip content={tooltipMessage || "Close"}>
      <Button
        icon={<Icon icon="cross" iconSize={Icon.SIZE_LARGE} />}
        minimal
        interactive
        large
        intent={Intent.DANGER}
        onClick={onClose}
      />
    </Tooltip>
  );
}
