import React from "react";
import { Spinner } from "@blueprintjs/core";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { TextInput } from "../../components/drawer-forms/inputs/text-input";
import { useFieldName2Value, useSetField } from "../../store/model-drawer-form";
import { ALLOWED_EMAIL_DOMAINS } from "../common/email-validation";
import { InputConfig } from "./input-config";

export function CreateEmailSender_FormContent({ activeField, setActiveField }) {
  const loading = useEnsureData("emailSenders");
  const fieldName2Value = useFieldName2Value();
  const setField = useSetField();
  return loading ? (
    <Spinner />
  ) : (
    <Inner
      fieldName2Value={fieldName2Value}
      setField={setField}
      activeField={activeField}
      setActiveField={setActiveField}
    />
  );
}

export function Inner({
  fieldName2Value,
  setField,
  activeField,
  setActiveField,
}) {
  const allowedEmailDomains: string = ALLOWED_EMAIL_DOMAINS.join(", ");

  const textInputConfigs: InputConfig[] = [
    {
      name: "first_name",
      label: "First Name",
      helperText:
        "The Email Sender's first name. This is how they will be addressed by the app.",
      leftIcon: "id-number",
    },
    {
      name: "last_name",
      label: "Last Name",
      helperText:
        "The Email Sender's last name. This is how they will be addressed by the app.",
      leftIcon: "barcode",
    },
    {
      name: "email_address",
      label: "Email Address",
      helperText: `Email address that will be used by the email sent for this sender. Only emails with the domain ${allowedEmailDomains} are allowed.`,
      leftIcon: "envelope",
    },
    {
      name: "title",
      label: "Title",
      helperText:
        "The Email Sender's title. This is how they will be addressed by the app.",
      leftIcon: "hat",
      allowSpaces: true,
    },
    {
      name: "phone_number",
      label: "Phone Number",
      helperText:
        "The Email Sender's phone number. This is how they will be addressed by the app.",
      leftIcon: "phone",
    },
  ];

  return (
    <>
      {textInputConfigs.map(
        ({ name, label, helperText, leftIcon, allowSpaces }) => (
          <TextInput
            key={name}
            helperText={helperText}
            label={label}
            name={name}
            leftIcon={leftIcon}
            value={fieldName2Value[name]}
            onValueChange={(value) => setField({ name, value, allowSpaces })}
            activeField={activeField}
            setActiveField={setActiveField}
          />
        )
      )}
    </>
  );
}
