import { Card, Classes, Elevation, H4, Tooltip } from "@blueprintjs/core";

import React, { Dispatch, SetStateAction } from "react";
import { actuallyFormatValue } from "./value-formatting";
import { AgColDef, AgColDefs } from "../AgTable/types";
import { TableRow } from "../../store/table-model-factory";
import styled from "styled-components/macro";
import { transparentize } from "polished";
import { CloseButton } from "./close-button";
import { CampaignStatsRowData } from "../../common/types";

export function CampaignStatsCard({
  campaignStatsRowData,
  colDefs,
  title,
  setStatsHidden,
}: {
  campaignStatsRowData: CampaignStatsRowData;
  colDefs: AgColDefs;
  setStatsHidden: Dispatch<SetStateAction<boolean>>;
  title?: string;
}) {
  console.log(
    "In CampaignStatsCard -- campaignStatsRowData: ",
    campaignStatsRowData
  );
  const handleOnClose = () => {
    setStatsHidden(true);
  };
  return (
    <CardContainer>
      <StyledCard elevation={Elevation.TWO}>
        <H4>{title}</H4>
        <StyledTableContainer>
          <StyledTable className={[Classes.HTML_TABLE].join(" ")}>
            <thead>
              <tr>
                {colDefs
                  .filter((cd) => !cd.hide)
                  .map((cd, idx) => (
                    <th key={idx}>
                      <TableHeaderTooltip key={idx} cd={cd} />
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {campaignStatsRowData.map((row, idx) => (
                <TableBodyRow key={idx} idx={idx} row={row} colDefs={colDefs} />
              ))}
            </tbody>
          </StyledTable>
        </StyledTableContainer>
        <CloseButtonContainer>
          <CloseButton
            tooltipMessage={title ?? `Hide ${title}`}
            onClose={handleOnClose}
          />
        </CloseButtonContainer>
      </StyledCard>
    </CardContainer>
  );
}

function TableBodyRow({
  idx,
  row,
  colDefs,
}: {
  idx: number;
  row: TableRow;
  colDefs: AgColDefs;
}): JSX.Element {
  return (
    <StyledTableRow rowIndex={idx} key={idx}>
      {colDefs
        .filter((cd) => !cd.hide)
        .map((cd) => (
          <TableBodyCell key={cd.field} row={row} cd={cd} />
        ))}
    </StyledTableRow>
  );
}

function TableBodyCell({
  row,
  cd,
}: {
  row: TableRow;
  cd: AgColDef;
}): JSX.Element {
  return (
    <td key={cd.field} className={`coltype-${cd.type}`}>
      {actuallyFormatValue(row, cd)}
    </td>
  );
}
function TableHeaderTooltip({ cd }: { cd: AgColDef }): JSX.Element {
  const content = (
    <TableHeaderTooltipContainer key={cd.field}>
      {cd.headerTooltip.split("\n").map((line, idx) => (
        <span key={idx}>
          {line}
          <br />
        </span>
      ))}
    </TableHeaderTooltipContainer>
  );

  const children = (
    <div>
      {cd.headerName.split("\n").map((line, idx) => (
        <span key={idx}>
          {line}
          <br />
        </span>
      ))}
    </div>
  );

  return (
    <Tooltip content={content} position={"top"} boundary={"viewport"}>
      {children}
    </Tooltip>
  );
}

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  max-width: 100%;
`;

const StyledTableContainer = styled.div`
  overflow-x: auto;
  max-width: 100%;
`;

const StyledCard = styled(Card)`
  position: relative;
  max-width: 100%;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const TableHeaderTooltipContainer = styled.div`
  max-width: 40vw;
`;
const StyledTable = styled.table`
  & > tbody > tr > td {
    &.coltype-integerColumn,
    &.coltype-floatColumn,
    &.coltype-percentageColumn {
      text-align: right;
    },                  
  }
`;
const StyledTableRow = styled.tr`
  ${(props) =>
    props.rowIndex % 2 !== 0
      ? `background-color: ${transparentize(0.3, "#293742")};`
      : ""}
`;
