import React from "react";
import { Spinner } from "@blueprintjs/core";
import AgTable from "../../components/AgTable";
import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import { useMeData } from "../../hooks/useMe";
import { useEndpoint } from "../../helpers/use-endpoint";
import { TableRow } from "../../store/table-model-factory";
import _ from "lodash";

interface SentCampaignEmailsTableProps {
  fullScreenEnabled?: boolean;
  [x: string]: any;
}

export function SentCampaignEmailsTable({
  fullScreenEnabled = true,
  ...restProps
}: SentCampaignEmailsTableProps) {
  const { data, loading } = useEndpoint("sent_campaign_emails");
  return loading ? (
    <Spinner />
  ) : (
    <SentCampaignEmailsTableInner
      fullScreenEnabled={fullScreenEnabled}
      rowData={data as TableRow[]}
      {...restProps}
    />
  );
}

function SentCampaignEmailsTableInner({
  selectedCampaignType,
  selectCampaignType,
  rowData,
  ...props
}: SentCampaignEmailsTableProps) {
  const colDefs = useColDefsForCampaignEmailsTable();
  return (
    <AgTable
      rowData={rowData}
      columnDefs={colDefs}
      tableName={"sent-campaign-emails"}
      {...props}
    />
  );
}

export function useColDefsForCampaignEmailsTable(): AgColDefs {
  const { role: myRole, timezone: myTz } = useMeData();
  let ret = [
    {
      field: "data__data__notification__alert__campaign_codename",
      headerName: "Campaign Codename",
      type: "categoryColumn",
      hide: true,
    },
    {
      field: "data__data__alert_codename_wavenum",
      headerName: "Wave",
      width: 100,
      type: "integerColumn",
      hide: true,
    },
    {
      field: "data__data__alert_codename",
      headerName: "Email Codename",
      type: "categoryColumn",
      hide: true,
    },
    {
      field: "sent_at",
      headerName: "Sent At",
      type: "timestampColumn",
      __timezone: myTz,
    },
    {
      colId: "sent_at",
      field: "sent_at",
      headerName: "Date",
      type: "dateColumn",
    },
    {
      field: "data__data__customer__customer_campaign_sender_email",
      headerName: "Sender Email",
      type: "categoryColumn",
    },
    {
      field: "data__data__customer__customer_campaign_sender_full_name",
      headerName: "Sender Name",
      type: "categoryColumn",
    },
    {
      field: "data__data__customer__customer_email",
      headerName: "Recipient Email",
      type: "textColumn",
    },
    {
      colId: "data__data__customer__customer_full_name",
      field: "data__data__customer__customer_first_name",
      headerName: "Recipient Name",
      valueGetter: (params) =>
        `${params.data.data__data__customer__customer_first_name} ${params.data.data__data__customer__customer_last_name}`,
      type: "textColumn",
    },
    {
      field: "data__subject",
      headerName: "Subject",
      cellClass: "ellipses-tooltip-cell",
      tooltipField: "data__subject",
      width: 380,
      type: "textColumn",
    },
    {
      colId: "data__cc_contacts__emails",
      field: "data__cc_contacts__emails",
      valueGetter: (params) =>
        _.isNil(params.data.data__cc_contacts__emails)
          ? ""
          : params.data.data__cc_contacts__emails.replace(",", ", "),
      headerName: "CC",
      type: "textColumn",
    },
    {
      headerName: "Customer ID",
      headerTooltip:
        "The User ID of the user (customer) to whom this email was sent",
      field: "data__data__customer__customer_id",
      type: "integerIdentifierColumn",
      width: 160,
    },
    {
      headerName: "MSM ID",
      headerTooltip:
        "The MSM ID of the user (customer) to whom this email was sent",
      field: "data__data__customer__customer_msm_id",
      type: "integerIdentifierColumn",
      width: 120,
    },
    {
      headerName: "Account Owner Email",
      field: "data__data__customer__customer_naive_account_owner_email",
      type: "textColumn",
      headerTooltip: "Account Owner's email address",
    },
    {
      headerName: "Account Owner Full Name",
      field: "data__data__customer__customer_naive_account_owner_full_name",
      type: "textColumn",
      headerTooltip: "Account Owner's full name",
    },
  ] as AgColDef[];

  return ret.filter((cd) => {
    // @ts-ignore
    return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
  });
}
