import { StatisticsForMultipleCampaigns } from "../../common/types";
import styled from "styled-components/macro";
import {
  Card,
  Intent,
  PopoverInteractionKind,
  Tag,
  Elevation,
  H4,
} from "@blueprintjs/core";
import { nfAmericaMoney } from "../AgTable/gridOptions";
import { Popover2 } from "@blueprintjs/popover2";
import React from "react";

const PERFORMANCE_INDICATORS = [
  {
    title: "Total Inc. Rev. Driven by Campaigns",
    label: "Total Incremental Revenue",
    field: "total_incremental_revenue_driven_by_cmax_campaign",
    description: "Total incremental revenue driven by all Customax campaigns",
  },
  {
    title: "Total New Revenue",
    label: "Total New Revenue",
    field: "total_new_revenue",
    description: "Total new revenue",
  },
];

const getCampaignNumberDisplayName = (campaignStats) => {
  const displayName = campaignStats.campaign_display_name;
  return displayName.includes(":") ? displayName.split(":")[0] : displayName;
};
export const OverallCampaignPerformance = ({
  statisticsForAllNeonCmaxCampaigns,
}: {
  statisticsForAllNeonCmaxCampaigns: StatisticsForMultipleCampaigns;
}): JSX.Element => {
  const getTotalPerformanceValue = (performanceIndicator) =>
    statisticsForAllNeonCmaxCampaigns.reduce(
      (acc, curr) =>
        acc +
          curr?.incremental_purchase_statistics_for_campaign?.[
            performanceIndicator.field
          ] ?? 0.0,
      0.0
    );
  return (
    <OverallPerformanceContainer>
      <PerformanceIndicatorsCard elevation={Elevation.TWO}>
        {PERFORMANCE_INDICATORS.map((performanceIndicator) => (
          <CampaignIndicatorRow key={performanceIndicator.field}>
            <H4>{performanceIndicator.title}</H4>
            <CampaignPerformanceIndicatorTag
              performanceValue={getTotalPerformanceValue(performanceIndicator)}
              performanceValueDescription={performanceIndicator.description}
              statisticsForAllNeonCmaxCampaigns={
                statisticsForAllNeonCmaxCampaigns
              }
              performanceField={performanceIndicator.field}
            />
          </CampaignIndicatorRow>
        ))}
      </PerformanceIndicatorsCard>
    </OverallPerformanceContainer>
  );
};

export function CampaignPerformanceIndicatorTag({
  performanceValue,
  performanceValueDescription,
  statisticsForAllNeonCmaxCampaigns,
  performanceField,
}: {
  performanceValue: number;
  performanceValueDescription: string;
  statisticsForAllNeonCmaxCampaigns: StatisticsForMultipleCampaigns;
  performanceField: string;
}): JSX.Element {
  const intent = Intent.PRIMARY;
  const formattedValue = nfAmericaMoney.format(performanceValue);
  const popoverContent = (
    <Card elevation={Elevation.TWO}>
      <p>
        {performanceValueDescription}
        {" is "}
        <b>{formattedValue}</b>
        {"."}
      </p>
      <p>{"Each campaign is contributing to this value as follows:"}</p>
      <ul>
        {statisticsForAllNeonCmaxCampaigns.map((campaignStats) => {
          const contributionValue =
            campaignStats?.incremental_purchase_statistics_for_campaign?.[
              performanceField
            ] ?? 0.0;
          const contributionPercentage =
            (100.0 * contributionValue) / performanceValue;
          return (
            <li key={campaignStats.campaign_codename}>
              <SingleCampaignContributionDetail>
                <span>
                  {getCampaignNumberDisplayName(campaignStats)}
                  {":"}
                </span>
                <span>{nfAmericaMoney.format(contributionValue)}</span>
                <span>{`(${contributionPercentage.toFixed(1)}%)`}</span>
              </SingleCampaignContributionDetail>
            </li>
          );
        })}
      </ul>
    </Card>
  );
  return (
    <Popover2
      autoFocus={false}
      usePortal={true}
      content={popoverContent}
      hoverCloseDelay={100}
      interactionKind={PopoverInteractionKind.HOVER}
    >
      <FigureTag large interactive={true} intent={intent}>
        {formattedValue}
      </FigureTag>
    </Popover2>
  );
}

const OverallPerformanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  margin-bottom: 10px;
  width: 100%;
`;

const PerformanceIndicatorsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  width: fit-content;
`;

const CampaignIndicatorRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 480px;
  min-height: 50px;
  align-items: center;
  justify-content: space-between;
`;

const FigureTag = styled(Tag)`
  margin-left: 1rem;
  font-weight: 600;
  padding: 2px;
`;

const SingleCampaignContributionDetail = styled.div`
  display: grid;
  grid: auto / 120px 1fr 80px;
  gap: 0.5rem;
  align-items: start;
  justify-content: space-between;
`;
