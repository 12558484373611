import { Computed, computed } from "easy-peasy";
import { StoreModel } from "./model";
import { TableModel, tableModelFactory, TableRow } from "./table-model-factory";
import { timeit } from "../helpers/timing";

export type SentCampaignEmailsModel = TableModel & {
  rowData: Computed<SentCampaignEmailsModel, TableRow[], StoreModel>;
};

export function getSentCampaignEmailsModel(): SentCampaignEmailsModel {
  return {
    ...tableModelFactory(
      "sentCampaignEmails",
      "sent_campaign_emails",
      (row) => row.id
    ),
    rowData: computed(
      [(state) => state.initialData],
      timeit((rowData) => rowData, "store.sentCampaignEmails.rowData")
    ),
  };
}
