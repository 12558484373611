import { Button, Icon, Intent } from "@blueprintjs/core";
import React from "react";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY } from "../../styles/constants";
import { useStoreActions } from "../../hooks/ep";
import { useCanCreateNewEmailSender } from "../common/use-can-create-new-email-sender";
import { CreateEmailSender_FormContent } from "./create-email-sender-form-content";
import { FormType, useOpenDrawerForm } from "../../store/model-drawer-form";
import { parseFieldsToString } from "../../common/parse-object-values";
import { EmailSender } from "../user-actions/declare-email-sender";

export function CreateNewEmailSenderButton() {
  const openDrawer = useOpenDrawerForm();
  const handleSubmit = useStoreActions(
    (a) => a.emailSenders.handleDeclareEmailSender
  );
  const canCreateNewEmailSender = useCanCreateNewEmailSender();

  const onClick = () => {
    const title = "Please enter details for the new Email Sender";

    const initialData = {
      fieldName2Value: parseFieldsToString(DEFAULT_INITIAL_EMAIL_SENDER_DATA),
    };

    const ContentComponent = CreateEmailSender_FormContent;
    openDrawer({
      initialData,
      title,
      ContentComponent,
      handleSubmit: handleSubmit,
      formType: FormType.CreateNewEmailSender,
    });
  };

  return (
    canCreateNewEmailSender && (
      <Tooltip
        title={"Create new Email Sender"}
        placement={"topLeft" as TooltipPlacement}
        mouseEnterDelay={AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY}
      >
        <Button
          css={`
            margin-left: 8px;
            margin-right: 8px;
          `}
          small
          minimal
          intent={Intent.SUCCESS}
          icon={<Icon icon="new-person" />}
          onClick={onClick}
        />
      </Tooltip>
    )
  );
}

const DEFAULT_INITIAL_EMAIL_SENDER_DATA: EmailSender = {
  first_name: "",
  last_name: "",
  email_address: "",
  title: "",
  phone_number: "",
  active: true,
};
