import { getStarsModel, StarsModel } from "./model-stars";
import { CmaxUserModel, getCmaxUserModel } from "./model-cmax-user";
import {
  SentCampaignEmailsModel,
  getSentCampaignEmailsModel,
} from "./model-sent-campaign-emails";
import { getEmailSendersModel, EmailSendersModel } from "./model-email-senders";

export interface TableModels {
  cmax_user: CmaxUserModel;
  stars: StarsModel;
  sentCampaignEmails: SentCampaignEmailsModel;
  emailSenders: EmailSendersModel;
  //
}

export type TableModelSliceName =
  | "cmax_user"
  | "stars"
  | "sentCampaignEmails"
  | "emailSenders";
//

export const tableModels: TableModels = {
  cmax_user: getCmaxUserModel(),
  stars: getStarsModel(),
  sentCampaignEmails: getSentCampaignEmailsModel(),
  emailSenders: getEmailSendersModel(),
  //
};
