import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
// import React, {useState} from "react";
import { PageContainer } from "./page-container";
import {
  makeAuthorizedGetRequestToBackend,
  makeUrl,
} from "../helpers/backendApi";
import { toaster } from "../toaster";
import { EmailInput, isValidEmail } from "../components/signin/email-input";
import { Button, Classes, Intent } from "@blueprintjs/core";
import { VERTICALLY_CENTER_IE11 } from "../vertically-center-ie11";
import { FormEvent, useState } from "react";

export function GlobalUnsubscribePage() {
  return <GlobalUnsubscribeForm />;
}

function GlobalUnsubscribeForm() {
  const [emailAddress, setEmailAddress] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    await createGlobalUnsubscribe(emailAddress);
    setIsSubmitting(false);
  };

  return (
    <PageContainer>
      <p>
        Enter the email address you wish to globally unsubscribe, then click
        SUBMIT. Customax will not send any emails to globally unsubscribed email
        addresses.
      </p>
      <div
        css={`
          width: 400px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <form onSubmit={onSubmit}>
          <div
            css={`
              margin-bottom: 32px;
            `}
          >
            <EmailInput
              email={emailAddress}
              setEmail={setEmailAddress}
              activeField={"email"}
              setActiveField={() => {}}
              helperText={"The email address you wish to globally unsubscribe"}
              placeholder={"Enter an email address.."}
              dataTestId={"global_unsubscribe_email_input"}
            />
          </div>
          <SubmitButton disabled={isSubmitting} emailAddress={emailAddress} />
        </form>
      </div>
    </PageContainer>
  );
}

function SubmitButton({ disabled, emailAddress }) {
  return (
    <Button
      data-testid="global_unsubscribe_submit_button"
      disabled={disabled || !isValidEmail(emailAddress)}
      intent={Intent.PRIMARY}
      className={Classes.ELEVATION_2}
      type="submit"
      fill
      large
      css={VERTICALLY_CENTER_IE11}
    >
      SUBMIT
    </Button>
  );
}

async function createGlobalUnsubscribe(emailAddress: string) {
  const resp = await makeAuthorizedGetRequestToBackend({
    url: makeUrl(`create_global_unsubscribe?email_address=${emailAddress}`),
    axiosConfig: {},
  });
  if (resp.status === 201) {
    toaster.success(
      `Success. ${emailAddress} has been globally unsubscribed and will no longer receive any emails from Customax.`
    );
  } else if (resp.status === 200) {
    // no-op, because already exists, so no need to create
    toaster.info(
      `No action taken. ${emailAddress} was already globally unsubscribed and will continue to not receive any emails from Customax.`
    );
  } else {
    toaster.error(
      `Something went wrong. Please reload and try again. If the issue persists, please email austin@saxecap.com for technical support.`
    );
  }
}
