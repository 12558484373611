import { parseBoolean } from "../../common/parse-boolean";
import { parseFieldsToString } from "../../common/parse-object-values";
import { FieldName2Value } from "../../store/model-drawer-form";
import { EmailSender } from "../user-actions/declare-email-sender";
import { formatPhoneNumber } from "./phone-validation";

export function parsePayloadToEmailSender(
  payload: FieldName2Value
): EmailSender {
  return {
    first_name: payload.first_name,
    last_name: payload.last_name,
    email_address: payload.email_address,
    title: payload.title,
    phone_number: payload.phone_number,
    uuid: payload.uuid,
    active: parseBoolean(payload.active),
  };
}

export function parseEmailSenderToPayload(
  emailSender: EmailSender
): FieldName2Value {
  const parsedEmailSender = parseFieldsToString(emailSender);

  const formattedPhoneNumber = formatPhoneNumber(
    parsedEmailSender.phone_number
  );

  return {
    first_name: parsedEmailSender.first_name,
    last_name: parsedEmailSender.last_name,
    email_address: parsedEmailSender.email_address,
    title: parsedEmailSender.title,
    phone_number: formattedPhoneNumber,
    uuid: parsedEmailSender.uuid,
    active: parsedEmailSender.active,
  };
}
