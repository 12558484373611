import styled from "styled-components/macro";
import { EmailSendersTable } from "../../email_senders/table/email-senders-table";

export function EmailSendersPage() {
  return (
    <PageContainer>
      <EmailSendersTable />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
