import { useCanCreateNewEmailSender } from "../common/use-can-create-new-email-sender";
import { CreateNewEmailSenderButton } from "../components/create-new-email-sender-button";
import React from "react";

export function useCustomRibbonLeftElements() {
  const canCreateNewEmailSender = useCanCreateNewEmailSender();
  return canCreateNewEmailSender
    ? [<CreateNewEmailSenderButton key="button--create-new-email-sender" />]
    : [];
}
