import React, { useState } from "react";
import styled from "styled-components/macro";

import { useFullScreenModeEnabled } from "../../store/model-misc";
import { StatsUnit } from "./stats-unit";
import { TableTabsUnit } from "./table-tabs-unit";
import { Spinner } from "@blueprintjs/core";
import { useEndpoint } from "../../helpers/use-endpoint";
import { StatisticsForMultipleCampaigns } from "../../common/types";
import { PageContainer } from "../page-container";

export function CampaignsPage(props): JSX.Element {
  return (
    <PageContainer>
      <Campaigns />
    </PageContainer>
  );
}

function Campaigns(props): JSX.Element {
  const { loading, data: statisticsForAllNeonCmaxCampaigns } =
    useEndpoint<StatisticsForMultipleCampaigns>("campaign_stats");
  const fullscreenOff = !useFullScreenModeEnabled();
  const [statsHidden, setStatsHidden] = useState<boolean>(false);
  const showCgnStats = fullscreenOff && !statsHidden;
  return (
    <Container>
      {showCgnStats &&
        (loading ? (
          <Spinner />
        ) : (
          <StatsUnit
            statisticsForAllNeonCmaxCampaigns={
              statisticsForAllNeonCmaxCampaigns
            }
            setStatsHidden={setStatsHidden}
          />
        ))}
      <SeparatorUnit />
      <TableTabsUnit />
    </Container>
  );
}

const SeparatorUnit = styled.div`
  height: 30px;
`;
const Container = styled.div`
  width: 100%;
  height: fit-content;
`;
