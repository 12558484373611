import _ from "lodash";

export function phoneNumberErrorMessage(phoneNumber: string): string | any {
  if (!phoneNumber) {
    return "Phone number is empty";
  }

  const possiblePhoneNumber: string =
    stripCharactersFromPhoneNumber(phoneNumber);
  const numericPhoneNumber: number = Number(possiblePhoneNumber);

  const phoneNumberLength: number = numericPhoneNumber.toString().length;

  if (_.isNaN(numericPhoneNumber) || phoneNumberLength !== 10) {
    return `Input [${phoneNumber}] does not represent a valid phone number`;
  }
}

function stripCharactersFromPhoneNumber(phoneNumber: string): string {
  const strippedCharactersRegex = /[\(\)\-\s\t\n\r\v\f]/g;
  const possibleNumber = phoneNumber.replace(strippedCharactersRegex, "");
  return possibleNumber;
}

export function formatPhoneNumber(number: string): string {
  const areaCode = number.substring(0, 3);
  const exchangeCode = number.substring(3, 6);
  const lineNumber = number.substring(6, 10);
  return `(${areaCode}) ${exchangeCode}-${lineNumber}`;
}
