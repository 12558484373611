import styled from "styled-components/macro";
import { Button, Icon, Intent } from "@blueprintjs/core";
import React from "react";

export type CampaignStatsSorterKey = "incremental_revenue" | "campaign_number";
const campaignStatsIncrementalRevenueSorter = (a, b) => {
  const a_total_incremental_revenue_driven_by_cmax_campaign =
    a.incremental_purchase_statistics_for_campaign
      ?.total_incremental_revenue_driven_by_cmax_campaign ?? 0;
  const b_total_incremental_revenue_driven_by_cmax_campaign =
    b.incremental_purchase_statistics_for_campaign
      ?.total_incremental_revenue_driven_by_cmax_campaign ?? 0;
  return (
    b_total_incremental_revenue_driven_by_cmax_campaign -
    a_total_incremental_revenue_driven_by_cmax_campaign
  );
};

const campaignStatsCampaignNumberSorter = (a, b) => {
  // extract campaign number with a regex from campaign_codename with format cgn<X><Y>,
  // where <X> is a number from 1 to 99 and <Y> a letter from the latin alphabet.
  const campaignNumberRegex = /cgn(\d{1,2})[a-z]/;
  const a_campaign_number = parseInt(
    a.campaign_codename.match(campaignNumberRegex)[1]
  );
  const b_campaign_number = parseInt(
    b.campaign_codename.match(campaignNumberRegex)[1]
  );
  return a_campaign_number - b_campaign_number;
};

export const SorterToggle = ({
  campaignStatsSorterKey,
  setCampaignStatsSorterKey,
}) => {
  return (
    <SorterToggleContainer>
      <Button
        small
        minimal
        intent={Intent.PRIMARY}
        icon={<Icon icon="sort" />}
        onClick={() =>
          setCampaignStatsSorterKey(
            campaignStatsSorterKey === "incremental_revenue"
              ? "campaign_number"
              : "incremental_revenue"
          )
        }
      >
        {`Sort by ${
          campaignStatsSorterKey === "incremental_revenue"
            ? "Campaign Number"
            : "Incremental Revenue"
        }`}
      </Button>
    </SorterToggleContainer>
  );
};

export const getCampaignStatsSorter = (
  campaignStatsSorterKey: CampaignStatsSorterKey
) => {
  return campaignStatsSorterKey === "incremental_revenue"
    ? campaignStatsIncrementalRevenueSorter
    : campaignStatsCampaignNumberSorter;
};

const SorterToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  width: 100%;
`;
