import React from "react";
import { Redirect, Route } from "react-router";
import { useIsAuthorized } from "./helpers/useAuthorization";
import useRedirect from "./hooks/useRedirect";
import { UserRole } from "./store/model-me";
import { Icon as BP3Icon } from "@blueprintjs/core";
import AppWrapper from "./components/wrapper/AppWrapper";
import { SigninPage } from "./pages/signin-page";
import { CmaxUsersPage } from "./cmax-users/pages/cmax-users-page";
import { CampaignsPage } from "./pages/campaigns/campaigns-page";
import { EmailSendersPage } from "./pages/email_senders/email-senders-page";
import { GlobalUnsubscribePage } from "./pages/global-unsubscribe-page";

export enum Routes {
  customer360 = "/customer360",
  //
  root = "/",
  dev = "/dev",
  signin = "/signin",
  cmax_users = "/cmax-users",
  //
  campaigns = "/campaigns",
  email_senders = "/email_senders",
  //
  global_unsubscribe = "/global-unsubscribe",
  //
  DEFAULT = campaigns,
}

export const DEFAULT_ROUTES_BY_ROLE = {
  [UserRole.SAXECAP_DEV]: Routes.DEFAULT,
  [UserRole.ADMIN_READ_WRITE]: Routes.DEFAULT,
  [UserRole.ADMIN_READ_ONLY]: Routes.DEFAULT,
  [UserRole.OBSERVER_READ_WRITE]: Routes.DEFAULT,
  [UserRole.OBSERVER_READ_ONLY]: Routes.DEFAULT,
  [UserRole.DEFAULT]: Routes.DEFAULT,
};

export const SAXECAP_ROUTES_PATT = /^\/.*$/;
export const ADMIN_ROUTES_PATT =
  /^\/(signin|campaigns|cmax-users|email_senders|global-unsubscribe)$/;
export const OBSERVER_ROUTES_PATT = ADMIN_ROUTES_PATT;
export const DEFAULT_ROUTES_PATT = /^\/(signin|campaigns)$/;

export const ROLE_ALLOWED_ROUTES_PATTS = {
  [UserRole.SAXECAP_DEV]: SAXECAP_ROUTES_PATT,
  [UserRole.ADMIN_READ_WRITE]: ADMIN_ROUTES_PATT,
  [UserRole.ADMIN_READ_ONLY]: ADMIN_ROUTES_PATT,
  [UserRole.OBSERVER_READ_WRITE]: OBSERVER_ROUTES_PATT,
  [UserRole.OBSERVER_READ_ONLY]: OBSERVER_ROUTES_PATT,
  [UserRole.DEFAULT]: DEFAULT_ROUTES_PATT,
};

export const USER_EXTRA_ALLOWED_ROUTES_PATTS = {};

export const leftNavMenuItems = [
  {
    urlPath: Routes.campaigns,
    title: "Campaigns",
    icon: <BP3Icon icon={"route"} />,
  },
  {
    urlPath: Routes.global_unsubscribe,
    title: "Global Unsubscribe",
    icon: <BP3Icon icon={"ban-circle"} />,
  },
  {
    urlPath: Routes.email_senders,
    title: "Valid Senders",
    icon: <BP3Icon icon={"envelope"} />,
  },
  {
    urlPath: Routes.cmax_users,
    title: "Customax Users",
    icon: <BP3Icon icon={"people"} />,
  },
];

export const BREADCRUMBS = [
  { href: Routes.cmax_users, icon: "people", text: "Customax Users" },
  {
    href: Routes.campaigns,
    icon: "route",
    text: "Campaigns",
  },
  {
    href: Routes.global_unsubscribe,
    icon: "ban-circle",
    text: "Global Unsubscribe",
  },
  {
    href: Routes.email_senders,
    icon: "envelope",
    text: "Valid Senders",
  },
];

function MyRoute({ route, Component, protect }) {
  let WrappedComponent;
  if (protect) {
    WrappedComponent = (props) => {
      const auth = useIsAuthorized(route);
      const doneLoading = !auth.loading;
      const isDefinitelyNotAuthorized = doneLoading && !auth.isAuthorized;
      const isDefinitelyAuthorized = doneLoading && auth.isAuthorized;
      useRedirect(() => isDefinitelyNotAuthorized, Routes.signin);
      return isDefinitelyAuthorized ? (
        <AppWrapper hideLeftNav={false}>
          <Component {...props} />
        </AppWrapper>
      ) : null;
    };
  } else {
    WrappedComponent = (props) => (
      <AppWrapper hideLeftNav={false}>
        <Component {...props} />
      </AppWrapper>
    );
  }
  return <Route exact path={route} component={WrappedComponent} key={route} />;
}

export const AllRoutes = [
  MyRoute({
    route: Routes.signin,
    Component: SigninPage,
    protect: false,
  }),
  MyRoute({
    route: Routes.cmax_users,
    Component: CmaxUsersPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.campaigns,
    Component: CampaignsPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.email_senders,
    Component: EmailSendersPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.global_unsubscribe,
    Component: GlobalUnsubscribePage,
    protect: true,
  }),
  <Route render={() => <Redirect to={Routes.signin} />} key="default_route" />,
];
