import { Icon as BP3Icon, Intent, ProgressBar } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components/macro";
import { Col, Row } from "antd";
// import { getScoreInfo } from "../../../helpers/get-score-info";
import _ from "lodash";
import { useRow } from "../../../../entities/helpers/use-row";

const YesChurnIcon = () => <BP3Icon icon="warning-sign" intent="danger" />;
const NoChurnIcon = () => <BP3Icon icon="shield" intent="success" />;
const ScoFacExplanation = styled.div`
  margin-bottom: 8px;
`;

function ScoFacIcon({ contrib }) {
  return contrib > 0 ? <YesChurnIcon /> : <NoChurnIcon />;
}

function ScoFacBar({ contrib, maxContrib }) {
  if (contrib <= 0) {
    contrib = -maxContrib / 2;
  }
  return (
    <ProgressBar
      animate={false}
      stripes={false}
      intent={contrib <= 0 ? Intent.SUCCESS : Intent.DANGER}
      value={Math.abs(contrib / maxContrib)}
    />
  );
}

function ScoreFactor({ name, contrib, valueExplanation, maxContrib }) {
  return (
    <Row
      className="feature-contribution-row"
      key={name}
      type="flex"
      justify="space-between"
      align="middle"
      gutter={16}
    >
      <Col>
        <ScoFacIcon contrib={contrib} />
      </Col>
      <Col
        css={`
          flex-grow: 1;
        `}
      >
        <ScoFacExplanation>{valueExplanation}</ScoFacExplanation>
        <ScoFacBar contrib={contrib} maxContrib={maxContrib} />
      </Col>
    </Row>
  );
}

const ScoFacWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > .feature-contribution-row {
    margin: 24px 0 0 0 !important;
  }
  & > .feature-contribution-row:first-child {
    margin-top: 0 !important;
  }
`;

const nfPct = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});

const nfInt = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

function isna(v) {
  return _.isNil(v) || _.isNaN(v);
}

function notna(v) {
  return !isna(v);
}

function getCustomerScoreInfoItem(key, customerRow) {
  const sfv = customerRow[key];
  const contrib = customerRow[`crs_contrib__${key}`] / 100;

  if (_.isNil(sfv) || _.isNil(contrib)) {
    return null;
  }

  const valueExplanation = explainScoreFactorValue(key, sfv, customerRow);
  if (_.isNil(valueExplanation)) {
    return null;
  }

  const ret = {
    contrib,
    valueExplanation,
    name: key,
    humanName: key,
    value: sfv,
  };
  // console.log(ret);
  return ret;
}

export function plur(
  nounCount,
  nounSingular,
  { nounPlural = null, zeroIsPlural = true } = {
    nounPlural: null,
    zeroIsPlural: true,
  }
) {
  const isSingular = nounCount === 1 || (nounCount === 0 && !zeroIsPlural);
  nounPlural = nounPlural || `${nounSingular}s`;
  const nounCountString = nfInt.format(nounCount);
  const nounString = isSingular ? nounSingular : nounPlural;
  return [nounCountString, nounString].join(" ");
}

function explainScoreFactorValue(key, sfv, customerRow) {
  if (key === "user_decline_over_past_quarter") {
    const hasDeclined = sfv > 0;
    const pctDeclineString = nfPct.format(Math.abs(sfv));
    const direction = hasDeclined ? "shrunk" : "grew";
    return `Active user base ${direction} by ${pctDeclineString}% over past quarter`;
  } else if (key === "event_volume_decline_over_past_quarter") {
    const hasDeclined = sfv > 0;
    const pctDeclineString = nfPct.format(Math.abs(sfv));
    const direction = hasDeclined ? "shrunk" : "grew";
    return `Event volume ${direction} by ${pctDeclineString}% over past quarter`;
    // } else if (key === "active_user_base") {
    //   const pctString = nfPct.format(sfv);
    //   return `${pctString}% of user base was active in past month`;
  } else if (key === "days_since_last_activity") {
    const ss = plur(sfv, "day");
    return `${ss} since last activity`;
  } else if (key === "number_of_users") {
    const ss = plur(sfv, "user");
    return `${ss} in user base`;
  } else if (key === "unused_service_percentage") {
    const pctString = nfPct.format(sfv);
    return `${pctString}% of services went unused over past month`;
  } else {
    return null;
  }
}

const SCORE_FACTOR_NAMES = [
  "user_decline_over_past_quarter",
  "event_volume_decline_over_past_quarter",
  // "active_user_base",
  "days_since_last_activity",
  "number_of_users",
  "unused_service_percentage",
];

function getCustomerScoreInfo(customerRow) {
  // console.log(customerRow);
  let ret = SCORE_FACTOR_NAMES.map((scoreFactorName) =>
    getCustomerScoreInfoItem(scoreFactorName, customerRow)
  )
    .filter((v) => !!v)
    .filter((v) => !_.isUndefined(v.contrib));
  ret = _.sortBy(ret, ({ contrib }) => -Math.abs(contrib));
  return ret;
}

const SCORE_INFO_FUNCS = {
  customer: getCustomerScoreInfo,
};

export function ScoreFactors({ id, sliceName }) {
  const row = useRow(id, sliceName);
  const scoreInfo = SCORE_INFO_FUNCS[sliceName](row);
  const top3_scoreInfo = scoreInfo.filter(
    ({ valueExplanation }) => !!valueExplanation
  );
  return (
    <ScoFacWrapper>
      {/*<PopConHeader>Score Factors</PopConHeader>*/}
      {top3_scoreInfo.map((info, idx) => (
        <ScoreFactor key={idx} maxContrib={1.0} {...info} />
      ))}
    </ScoFacWrapper>
  );
}
