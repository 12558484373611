import React from "react";
import { parseBoolean } from "../../common/parse-boolean";
import AgTable from "../../components/AgTable";
import { AgColDefs } from "../../components/AgTable/types";
import { useStoreActions, useStoreState } from "../../hooks/ep";
import { toaster } from "../../toaster";
import { formatPhoneNumber } from "../common/phone-validation";
import { useCanEditEmailSender } from "../common/use-can-create-new-email-sender";
import { MenuItem_EditEmailSender } from "../components/edit-email-sender-menu-button";
import { useCustomRibbonLeftElements } from "./use-custom-ribbon-left-elements";

export function EmailSendersTableInner({
  fullScreenEnabled = true,
  ...restProps
}) {
  let rowData = useStoreState((s) => s.emailSenders.initialData);

  const columnDefs = useColumnDefs();
  const customRibbonLeftElements = useCustomRibbonLeftElements();

  return (
    <AgTable
      rowData={rowData}
      tableName="Email Senders"
      columnDefs={columnDefs}
      customRibbonLeftElements={customRibbonLeftElements}
      {...restProps}
    />
  );
}

function useColumnDefs(): AgColDefs {
  const canEditEmailSender = useCanEditEmailSender();

  const handleUpdate = useStoreActions(
    (a) => a.emailSenders.handleEditEmailSender
  );

  return [
    {
      headerName: "First Name",
      field: "first_name",
      type: "textColumn",
    },
    {
      headerName: "Last Name",
      field: "last_name",
      type: "textColumn",
    },
    {
      headerName: "Email",
      field: "email_address",
      type: "textColumn",
    },
    {
      headerName: "Title",
      field: "title",
      type: "textColumn",
    },
    {
      headerName: "Phone Number",
      field: "phone_number",
      type: "textColumn",
      valueFormatter: ({ value }) => formatPhoneNumber(value),
    },
    {
      headerName: "Active",
      field: "active",
      headerTooltip:
        "Whether the individual can be used as a Customax campaign email sender. If unchecked (false), then will not be used as a Customax campaign email sender.",
      type: "booleanColumn",
      width: 166,
      ...(canEditEmailSender
        ? {
            cellRenderer: "checkBoxCellRenderer",
            cellRendererParams: {
              crp: {
                handler: handleUpdate,
                buildPayload: (data, field, value) => ({
                  ...data,
                  [field]: value,
                }),
              },
            },
            valueParser: (params) => parseBoolean(params.newValue),
            valueSetter: (params) => {
              const isValid =
                typeof params.newValue === "boolean" &&
                params.newValue !== params.oldValue;
              if (isValid) {
                params.data.student_flag__do_not_email = params.newValue;
                return true;
              } else {
                toaster.error(
                  "Please submit the word 'true' or the word 'false'"
                );
                return false;
              }
            },
          }
        : {}),
    },
    {
      headerName: "",
      field: "action",
      cellRenderer: "takeActionButtonCellRenderer",
      cellRendererParams: {
        crp: {
          popoverMenuItems: [MenuItem_EditEmailSender],
          getDisabled: (data) => !canEditEmailSender,
        },
      },
      width: 48,
      pinned: "right",
      __authorized: canEditEmailSender,
    },
    // {
    //   headerName: "UUID",
    //   field: "uuid",
    //   type: "textColumn",
    // },
  ];
}
