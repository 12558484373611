import {
  action,
  Action,
  ActionCreator,
  computed,
  Computed,
  Thunk,
  thunk,
  ThunkOn,
  thunkOn,
} from "easy-peasy";
import { StoreModel } from "./model";
import { Injections } from "./store-injections";
import { useStoreActions, useStoreState } from "../hooks/ep";
import { UserRole, VALID_NEW_USER_ROLES } from "./model-me";
import { VALID_TIMEZONES } from "../common/valid-timezones";
import _ from "lodash";
import { isAllowedEmailDomainSender } from "../email_senders/common/email-validation";
import { phoneNumberErrorMessage } from "../email_senders/common/phone-validation";

export interface DrawerFormModel {
  NAME: string;
  //
  resetData: Thunk<DrawerFormModel, void, Injections, StoreModel>;
  onLogin: ThunkOn<DrawerFormModel, Injections, StoreModel>;
  onResetMeData: ThunkOn<DrawerFormModel, Injections, StoreModel>;
  //
  initialData: null | InitialData;
  title: null | string;
  formType: null | FormType;
  ContentComponent: null | any;
  handleSubmit: null | any;
  formStoreModelFactory: null | any;
  isOpen: Computed<DrawerFormModel, boolean, StoreModel>;
  open: Action<
    DrawerFormModel,
    {
      initialData: InitialData;
      title: string;
      ContentComponent: any;
      handleSubmit: any;
      formType: FormType;
    }
  >;
  close: Action<DrawerFormModel>;
  //
  onOpen: ThunkOn<DrawerFormModel, Injections, StoreModel>;
  fieldName2Value: FieldName2Value;
  setField: Action<DrawerFormModel, SetFieldPayload>;
  dataIsSubmittable: Computed<DrawerFormModel, boolean>;
  errorMessage: Computed<DrawerFormModel, null | string>;
}

export const drawerFormModel: DrawerFormModel = {
  NAME: "drawerForm",
  //
  resetData: thunk((actions) => {
    actions.close();
  }),
  onLogin: thunkOn(
    (__, storeActions) => storeActions.me.receiveInitialData,
    (actions, target, { getStoreState }) => {
      actions.resetData();
    }
  ),
  onResetMeData: thunkOn(
    (__, storeActions) => storeActions.me.resetData,
    (actions) => {
      actions.resetData();
    }
  ),
  //
  initialData: null,
  title: null,
  formType: null,
  ContentComponent: null,
  handleSubmit: null,
  formStoreModelFactory: null,
  isOpen: computed([(s) => s.initialData], (initialData) => !!initialData),
  open: action(
    (
      state,
      { initialData, title, ContentComponent, handleSubmit, formType }
    ) => {
      state.initialData = initialData;
      state.title = title;
      state.ContentComponent = ContentComponent;
      state.handleSubmit = handleSubmit;
      state.formType = formType;
    }
  ),
  close: action((state) => {
    state.fieldName2Value = {};
    state.initialData = null;
    state.title = null;
    state.ContentComponent = null;
    state.handleSubmit = null;
  }),
  //
  fieldName2Value: {},
  setField: action((state, { name, value, allowSpaces }) => {
    if (allowSpaces) {
      state.fieldName2Value[name] = value || "";
    } else {
      state.fieldName2Value[name] = (value || "").trim();
    }
  }),
  onOpen: thunkOn(
    (a, __) => a.open,
    (actions, { payload: { initialData } }) => {
      Object.entries(initialData?.fieldName2Value ?? {}).forEach(
        ([name, value]) => {
          actions.setField({ name, value });
        }
      );
    }
  ),
  dataIsSubmittable: computed(
    [(s) => s.errorMessage],
    (errorMessage) => !errorMessage
  ),
  errorMessage: computed(
    [(s) => s.fieldName2Value, (s) => s.formType],
    (fieldName2Value, formType) => {
      return errorMessageByFormType(fieldName2Value, formType);
    }
  ),
};

export const useOpenDrawerForm = () =>
  useStoreActions((a) => a.drawerForm.open);

export const useCloseDrawerForm = () =>
  useStoreActions((a) => a.drawerForm.close);

export const useDrawerForm = () => {
  const title = useStoreState((a) => a.drawerForm.title);
  const ContentComponent = useStoreState((s) => s.drawerForm.ContentComponent);
  const handleSubmit = useStoreState((s) => s.drawerForm.handleSubmit);

  const isOpen = useStoreState((s) => s.drawerForm.isOpen);

  const open = useOpenDrawerForm();
  const close = useCloseDrawerForm();

  return {
    title,
    ContentComponent,
    handleSubmit,
    isOpen,
    open,
    close,
  };
};

export function useFormErrorMessage(): string | null {
  return useStoreState((s) => s.drawerForm.errorMessage);
}

export function useFieldName2Value(): FieldName2Value {
  return useStoreState((s) => s.drawerForm.fieldName2Value);
}

export function useDataIsSubmittable(): boolean {
  return useStoreState((s) => s.drawerForm.dataIsSubmittable);
}

export function useSetField(): SetFieldActionCreator {
  return useStoreActions((a) => a.drawerForm.setField);
}

export type FieldName = string;
export type FieldValue = string;
export type FieldName2Value = { [name: string]: FieldValue };
export enum FormType {
  CreateNewCmaxUser = "createNewCmaxUser",
  CreateNewEmailSender = "createNewEmailSender",
  EditEmailSender = "editEmailSender",
}
interface InitialData {
  fieldName2Value: FieldName2Value;
}
export interface SetFieldPayload {
  name: FieldName;
  value: FieldValue;
  allowSpaces?: boolean;
}
export type SetFieldActionCreator = ActionCreator<SetFieldPayload>;

function errorMessageByFormType(
  fieldName2Value: FieldName2Value,
  formType: FormType
): string | null {
  if (formType === FormType.CreateNewCmaxUser) {
    const { email, first_name, last_name, role, timezone } = fieldName2Value;
    if (_.isNil(email)) {
      return null;
    } else if (!email.includes("@")) {
      return "Please provide a valid email address";
    } else if (!first_name) {
      return "Please provide a first name";
    } else if (!last_name) {
      return "Please provide a last name";
    } else if (!VALID_NEW_USER_ROLES.includes(role as UserRole)) {
      return "Please provide a valid role";
    } else if (!VALID_TIMEZONES.includes(timezone)) {
      return "Please provide a valid timezone";
    }
  }
  if (
    formType === FormType.CreateNewEmailSender ||
    formType === FormType.EditEmailSender
  ) {
    const { email_address, first_name, last_name, title, phone_number } =
      fieldName2Value;

    if (_.isNil(email_address)) {
      return null;
    } else if (!email_address.includes("@")) {
      return "Please provide a valid email address";
    } else if (!isAllowedEmailDomainSender(email_address)) {
      return "Please provide a valid email address ending in @review.com";
    } else if (!first_name) {
      return "Please provide a first name";
    } else if (!last_name) {
      return "Please provide a last name";
    } else if (!title) {
      return "Please provide a title";
    }

    return phoneNumberErrorMessage(phone_number);
  }
}
