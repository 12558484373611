import React from "react";
import {
  Classes,
  Popover,
  PopoverInteractionKind,
  Position,
} from "@blueprintjs/core";
import { ChuScoPopoverContent } from "./popover-content";
import { ChuScoPopoverTarget } from "./popover-target";
import "styled-components/macro";
import _ from "lodash";

export function ChurnScoreCellRenderer({
  value: churnScore,
  data: { id },
  position,
  width,
  fill,
}) {
  const churnScoreIsMissing = _.isNil(churnScore) || _.isNaN(churnScore);

  return <ChuScoPopoverTarget churnScore={churnScore} width={width} />;
}

export function ChuScoPopover({
  churnScore,
  id,
  sliceName,
  position,
  width,
  fill,
}) {
  return (
    <Popover
      content={
        <ChuScoPopoverContent
          churnScore={churnScore}
          id={id}
          sliceName={sliceName}
        />
      }
      position={position ?? Position.RIGHT_TOP}
      interactionKind={PopoverInteractionKind.HOVER}
      popoverClassName={`${Classes.ELEVATION_4}`}
      hoverOpenDelay={300}
      fill={fill ?? true}
      boundary={"viewport"}
    >
      <ChuScoPopoverTarget churnScore={churnScore} width={width} />
    </Popover>
  );
}
