import React from "react";
import { Spinner } from "@blueprintjs/core";
import AgTable from "../../components/AgTable";
import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import { useMeData } from "../../hooks/useMe";
import { useEndpoint } from "../../helpers/use-endpoint";
import { TableRow } from "../../store/table-model-factory";
import _ from "lodash";

interface IncrementalPurchaseItemsTableProps {
  fullScreenEnabled?: boolean;
  [x: string]: any;
}

const campaignCodenamesListSortKeyGetter = (v) => {
  // sort by length of array after splitting by comma
  if (_.isNil(v)) {
    return -1;
  }
  const length = v.split(",").length;
  let weight = length * 1_000;
  if (length === 1) {
    // add number extracted from regex to the weight
    const match = v.match(/(\d+)/);
    if (match) {
      weight += parseInt(match[0]);
    }
  }
  return weight;
};
export function IncrementalPurchaseItemsTable({
  fullScreenEnabled = true,
  ...restProps
}: IncrementalPurchaseItemsTableProps) {
  const { data, loading } = useEndpoint("incremental_purchase_items");
  return loading ? (
    <Spinner />
  ) : (
    <IncrementalPurchaseItemsTableInner
      fullScreenEnabled={fullScreenEnabled}
      rowData={data as TableRow[]}
      {...restProps}
    />
  );
}

function IncrementalPurchaseItemsTableInner({
  selectedCampaignType,
  selectCampaignType,
  rowData,
  ...props
}: IncrementalPurchaseItemsTableProps) {
  const colDefs = useColDefsForIncrementalPurchaseItemsTable();
  const initialCategoryFilters = {
    customer_is_any_campaign_target: {
      value: [true],
    },
  };
  return (
    <AgTable
      rowData={rowData}
      columnDefs={colDefs}
      tableName={"incremental-purchase-items"}
      categoryFilters={initialCategoryFilters}
      {...props}
    />
  );
}

export function useColDefsForIncrementalPurchaseItemsTable(): AgColDefs {
  const { role: myRole, timezone: myTz } = useMeData();
  let ret = [
    {
      field: "PurchaseItemDate", //
      headerName: "Purchase Date",
      type: "timestampColumn",
      headerTooltip: "Purchase item date",
      __timezone: myTz,
    },
    {
      field: "TestTypeDescription",
      headerName: "Test Type",
      type: "categoryColumn",
      valueFormatter: (params) => params.value.replace("_", " "),
    },
    {
      field: "ProductDescription",
      headerName: "Purchase Line Item Name",
      type: "textColumn",
      width: 400,
    },
    {
      field: "FinalAmount",
      headerName: "Purchase Line Item Amount",
      type: "moneyColumn",
      width: 150,
    },
    {
      field: "customer_full_name",
      headerName: "Customer Name",
      type: "textColumn",
    },
    {
      field: "customer_is_any_campaign_target",
      headerName: "Is Target",
      type: "booleanColumn",
    },
    {
      field: "customer_target_group_campaign_codenames",
      headerName: "Customer Target Group Campaign Codenames",
      filter: "tagsFilterer",
      __customSortKeyGetter: campaignCodenamesListSortKeyGetter,
      width: 220,
    },
    {
      field: "customer_is_any_campaign_control",
      headerName: "Is Control",
      type: "booleanColumn",
    },
    {
      field: "customer_control_group_campaign_codenames",
      headerName: "Customer Control Group Campaign Codenames",
      type: "categoryColumn",
      filter: "tagsFilterer",
      __customSortKeyGetter: campaignCodenamesListSortKeyGetter,
      width: 220,
    },
    {
      field: "customer_email",
      headerName: "Customer Email",
      type: "textColumn",
    },
    {
      headerName: "Customer ID",
      headerTooltip:
        "The User ID of the user (customer) for which this item was purchased",
      field: "customer_id",
      type: "integerIdentifierColumn",
      width: 160,
    },
    {
      headerName: "MSM ID",
      headerTooltip:
        "The MSM ID of the user (customer) for which this item was purchased",
      field: "customer_msm_id",
      type: "integerIdentifierColumn",
      width: 120,
    },
    {
      headerName: "Purchase Item ID",
      field: "PurchaseItemId",
      width: 200,
      hide: true,
    },
    {
      field: "ModalityTypeDescription",
      headerName: "Modality Type",
      type: "categoryColumn",
      hide: true,
    },
    {
      field: "ProductTypeDescription",
      headerName: "Product Type",
      type: "categoryColumn",
      hide: true,
    },
  ] as AgColDef[];

  return ret.filter((cd) => {
    // @ts-ignore
    return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
  });
}
