import React, { useState } from "react";
import { AgColDefs } from "../../components/AgTable/types";
import styled from "styled-components/macro";
import { CampaignStatsCard } from "../../components/campaign-stats/campaign-stats-card";
import _ from "lodash";
import {
  CampaignStatsRow,
  CampaignStatsRowData,
  IncrementalPurchaseStatisticsForSingleCampaign,
  SendgridActivityEventStatisticsForSingleCampaignWave,
  StatisticsForMultipleCampaigns,
  StatisticsForSingleCampaign,
} from "../../common/types";
import { OverallCampaignPerformance } from "../../components/campaign-stats/overall-campaign-performance";
import {
  CampaignStatsSorterKey,
  getCampaignStatsSorter,
  SorterToggle,
} from "../../components/campaign-stats/campaign-stats-sorter";

export function StatsUnit({
  statisticsForAllNeonCmaxCampaigns,
  setStatsHidden,
}: {
  statisticsForAllNeonCmaxCampaigns: StatisticsForMultipleCampaigns;
  setStatsHidden: any;
}): JSX.Element {
  const campaignStatsColDefs = useCampaignStatsColDefs();
  const [campaignStatsSorterKey, setCampaignStatsSorterKey] =
    useState<CampaignStatsSorterKey>("incremental_revenue");
  const campaignStatsSorter = getCampaignStatsSorter(campaignStatsSorterKey);
  return _.size(statisticsForAllNeonCmaxCampaigns) ? (
    <StatsContainer>
      <OverallCampaignPerformance
        statisticsForAllNeonCmaxCampaigns={statisticsForAllNeonCmaxCampaigns}
      />
      <SorterToggle
        campaignStatsSorterKey={campaignStatsSorterKey}
        setCampaignStatsSorterKey={setCampaignStatsSorterKey}
      />
      {statisticsForAllNeonCmaxCampaigns
        .filter((statisticsForSingleCampaign) => {
          const activity_stats =
            statisticsForSingleCampaign.sendgrid_activity_event_statistics_for_each_campaign_wave;
          return activity_stats && activity_stats.length > 0;
        })
        .sort(campaignStatsSorter)
        .map((statisticsForSingleCampaign, idx) => {
          return (
            <CampaignStatsCard
              key={idx}
              campaignStatsRowData={toCampaignStatsRowData(
                statisticsForSingleCampaign
              )}
              colDefs={campaignStatsColDefs}
              title={statisticsForSingleCampaign.campaign_display_name}
              setStatsHidden={setStatsHidden}
            />
          );
        })}
    </StatsContainer>
  ) : null;
}

function toCampaignStatsRowData(
  statisticsForSingleCampaign: StatisticsForSingleCampaign
): CampaignStatsRowData {
  const {
    sendgrid_activity_event_statistics_for_each_campaign_wave,
    incremental_purchase_statistics_for_campaign,
  } = statisticsForSingleCampaign;

  if (_.isNull(sendgrid_activity_event_statistics_for_each_campaign_wave)) {
    return [];
  } else {
    const makeCampaignStatsRow = (
      sendgrid_activity_event_statistics_for_single_campaign_wave: SendgridActivityEventStatisticsForSingleCampaignWave
    ): CampaignStatsRow => ({
      ...sendgrid_activity_event_statistics_for_single_campaign_wave,
      //
      ...getIncrementalPurchaseStatisticsForSingleCampaign(
        sendgrid_activity_event_statistics_for_single_campaign_wave,
        incremental_purchase_statistics_for_campaign
      ),
    });

    return sendgrid_activity_event_statistics_for_each_campaign_wave.map(
      makeCampaignStatsRow
    );
  }
}

function getIncrementalPurchaseStatisticsForSingleCampaign(
  sendgrid_activity_event_statistics_for_single_campaign_wave: SendgridActivityEventStatisticsForSingleCampaignWave,
  incremental_purchase_statistics_for_campaign: IncrementalPurchaseStatisticsForSingleCampaign
) {
  const { campaign_wave_num, campaign_codename } =
    sendgrid_activity_event_statistics_for_single_campaign_wave;

  if (
    campaign_wave_num === 1 &&
    !_.isNull(incremental_purchase_statistics_for_campaign)
  ) {
    return incremental_purchase_statistics_for_campaign;
  } else {
    return getDefaultIncrementalPurchaseStatisticsForSingleCampaign(
      campaign_codename
    );
  }
}

function getDefaultIncrementalPurchaseStatisticsForSingleCampaign(
  campaign_codename: string
): IncrementalPurchaseStatisticsForSingleCampaign {
  return {
    campaign_codename,
    n_incremental_purchase_items: NaN,
    n_paid_incremental_purchase_items: NaN,
    n_incremental_purchases: NaN,
    n_paid_incremental_purchases: NaN,
    n_customers_with_incremental_purchases: NaN,
    n_customers_with_paid_incremental_purchases: NaN,
    total_new_revenue: NaN,
    total_incremental_revenue_driven_by_cmax_campaign: NaN,
    //
    n_targeted_customers__entire_campaign: NaN,
    avg_new_revenue_among_targeted_customers: NaN,
    //
    n_delivered_customers__entire_campaign: NaN,
    avg_new_revenue_among_delivered_customers: NaN,
    //
    n_controls: NaN,
    avg_new_revenue_among_controls: NaN,
  };
}

const StatsContainer = styled.div`
  max-width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-between;
`;

/* TODO:
 *
 * 1. Why say "customers in the group" when the definition of "group" is unclear? If we don't say "customers in the group", then how do we make it clear which set of customers the any given statistic is aggregated over?
 *
 * 2. "after the campaign commenced" isn't the full story and is actually somewhat misleading, both for Target group members and for Control group members.
 *     a. For Target group members, a more correct statement would be "after the date each customer received their first email from this campaign (different dates for different customers, since sending was staggered)".
 *     b. For the Control group members, a more correct statement would be "after the final email of the first wave of emails for this campaign was sent to the Target group" or "after all Target group members received their first email from this campaign"
 *
 * 3. How do we deal with the fact that some of these columns are campaign-level (e.g. "Incremental Revenue") while others are campaign-wave level (e.g. "Email Codename", "Latest") while others are campaign-wave-group level (e.g. "# Customers", "% Opened")? Don't we **have to** show one row per campaign per wave per group PLUS one row per campaign per group to properly display all the data (with "N/A" values used wherever the granularity of the row isn't compatible with the granularity of the metric)?
 *
 * 4. Which of the incremental columns are worth showing? What about just "# Customers Contributing to Incremental Revenue", "Incremental Revenue", and "Avg. Inc. Rev. per Customer", with an AgTable showing every incremental purchase item?
 *
 * 5. How do we plan for adding in Control group stats so we don't have to reinvent the wheel with these stats tables and with the incremental purchase items AgTable?
 *
 */

function useCampaignStatsColDefs(): AgColDefs {
  return [
    {
      field: "campaign_wave_num",
      headerName: "Wave",
      type: "categoryColumn",
      headerTooltip: "The campaign wave informing the row's statistics",
      hide: true,
    },
    {
      field: "campaign_wave_codename",
      headerName: "Email Codename",
      type: "categoryColumn",
      headerTooltip:
        "The campaign email codename(s) informing the row's statistics",
    },
    {
      field: "first_email_sent_at",
      headerName: "Start",
      type: "dateColumn",
      headerTooltip: "The date the first wave of emails was sent",
    },
    {
      field: "latest_email_sent_at",
      headerName: "Latest",
      type: "dateColumn",
      headerTooltip: "The date the latest wave of emails was sent",
    },
    {
      field: "n_targeted_customers",
      headerName: "# Customers Targeted (Wave)",
      type: "integerColumn",
      headerTooltip:
        "The number of campaign candidates (customers) targeted by this campaign email (i.e. number of customers who we attempted to send this campaign wave email)",
    },
    {
      field: "n_targeted_customers__entire_campaign",
      headerName: "# Customers Targeted",
      type: "integerColumn",
      headerTooltip:
        "The number of campaign candidates (customers) targeted by at least one email in this campaign email (i.e. number of customers who we attempted to send at least one wave email for this campaign)",
      hide: true,
    },
    {
      field: "n_delivered_customers",
      headerName: "# Customers Delivered (Wave)",
      type: "integerColumn",
      headerTooltip:
        "The number of campaign candidates (customers) to whom the campaign email was successfully delivered for this wave",
    },
    {
      field: "biased_pct_opened_among_delivered_customers",
      headerName: "% Opened (Wave)",
      type: "percentageColumn",
      headerTooltip:
        "The percentage of delivered campaign emails that have been opened at least once for this wave",
    },
    {
      field: "biased_n_clicked",
      headerName: "# Clicked (Wave)",
      type: "integerColumn",
      headerTooltip:
        "The number of campaign emails that have been clicked at least once for this wave",
    },
    {
      field: "total_incremental_revenue_driven_by_cmax_campaign",
      headerName: "Total Inc. Rev. Driven By Campaign",
      type: "moneyColumn",
      headerTooltip:
        "The total incremental revenue driven by this campaign ([# Customers Delivered] * MAX(0, ([Avg. Inc. Rev. per Delivered Customer] - [Avg. Inc. Rev. per Control])))",
    },
    {
      field: "total_new_revenue",
      headerName: "Total Revenue",
      type: "moneyColumn",
      headerTooltip:
        "The total revenue from paid incremental purchases among delivered customers",
    },
    {
      field: "avg_new_revenue_among_delivered_customers",
      headerName: "Avg. Inc. Rev.",
      type: "moneyColumn",
      headerTooltip:
        "The average revenue per (delivered) customer from paid incremental purchases ([Incremental Revenue Among Targets] / [# Customers Delivered])",
    },
    {
      field: "n_delivered_customers__entire_campaign",
      headerName: "# Customers Delivered",
      type: "integerColumn",
      headerTooltip:
        "The number of targeted customers who have been successfully delivered at least one email for this campaign",
    },
    {
      field: "n_controls",
      headerName: "# Controls",
      type: "integerColumn",
      headerTooltip:
        "The number of customers in the control group (campaign candidates whose campaign emails were held back)",
    },
    {
      field: "avg_new_revenue_among_controls",
      headerName: "Avg. Inc. Rev. per Control",
      type: "moneyColumn",
      headerTooltip:
        "The average revenue per control from paid incremental purchases ([Incremental Revenue Among Controls] / [# Controls])",
    },
    {
      field: "n_customers_with_incremental_purchases",
      headerName: "# Customers w/ Inc. Purchases",
      type: "integerColumn",
      headerTooltip:
        "A count of the delivered customers who have incremental purchases",
    },
    {
      field: "n_customers_with_paid_incremental_purchases",
      headerName: "# Customers w/ Paid Inc. Purchases",
      // headerName: "# Customers Contributing to Inc. Revenue",
      type: "integerColumn",
      headerTooltip:
        "A count of the delivered customers who have paid incremental purchases",
      // "A count of the delivered customers who are contributing to incremental revenue",
    },
    {
      field: "n_incremental_purchase_items",
      headerName: "# Inc. Purchase Items",
      type: "integerColumn",
      headerTooltip:
        "A count of the purchase line items that have appeared for delivered customers after the campaign commenced",
    },
    {
      field: "n_paid_incremental_purchase_items",
      headerName: "# Paid Inc. Purchase Items",
      type: "integerColumn",
      headerTooltip:
        "A count of the paid (FinalAmount > $0.00) purchase line items that have appeared for delivered customers after the campaign commenced",
    },
    {
      field: "n_incremental_purchases",
      headerName: "# Inc. Purchases",
      type: "integerColumn",
      headerTooltip:
        "A count of the purchases that have appeared for delivered customers after the campaign commenced",
    },
    {
      field: "n_paid_incremental_purchases",
      headerName: "# Paid Inc. Purchases",
      type: "integerColumn",
      headerTooltip:
        "A count of the paid (FinalAmount > $0.00) purchases that have appeared for delivered customers after the campaign commenced",
    },
    {
      field: "avg_new_revenue_among_targeted_customers",
      headerName: "Avg. Inc. Rev. per Targeted Customer",
      type: "moneyColumn",
      headerTooltip:
        "The average revenue per (targeted) customer from paid incremental purchases ([Incremental Revenue] / [# Customers Targeted])",
      hide: true,
    },
  ];
}
