import {
  dateVF,
  floatVF,
  integerVF,
  isna,
  moneyVF,
  percentageVF,
} from "../../components/AgTable/value-formatting";
import { AgColDef } from "../../components/AgTable/types";
import _ from "lodash";

export function actuallyFormatValue(row, cd: AgColDef) {
  let v = row[cd.field];

  if (_.isNaN(row[cd.field]) || _.isNil(row[cd.field])) {
    // @ts-ignore
    return cd.__renderNansAsEmpty ? "" : "N/A";
  } else {
    if (_.isNumber(row[cd.field])) {
      v = Math.max(0, row[cd.field]);
    }

    return formatValue(v, cd.type as string);
  }
}

function formatValue(value: any, columnType: string): string {
  const vf = FORMATTERS_BY_TYPE[columnType];
  return vf(value);
}

const FORMATTERS_BY_TYPE = {
  moneyColumn: (value) => moneyVF({ value }),
  integerColumn: (value) => integerVF({ value }),
  floatColumn: (value) => (isna(value) ? "" : floatVF({ value })),
  percentageColumn: (value) => percentageVF({ value }),
  textColumn: (value) => value,
  categoryColumn: (value) => value,
  dateColumn: (value) => dateVF({ value }),
};
