import {
  makeAuthorizedPostRequestToBackend,
  makeUrl,
} from "../helpers/backendApi";
import { AxiosResponse } from "axios";

export type ActionCode = "create_new_cmax_user" | "declare_email_sender";

export interface ActionPayload {
  [key: string]: any;
}

export interface DataSnapshot {
  [key: string]: any;
}

export interface UserAction {
  id: number;
  flask_request_id: number;
  submitted_by: string;
  submitted_at: string;
  action_code: ActionCode;
  action_payload: ActionPayload;
}

export type AdjustmentNotes = string;

const SubmitUserActionPromise = Promise;
export type SubmitUserActionPromise = Promise<AxiosResponse<UserAction>>;

export async function submitUserActionPostRequest(
  action_code: ActionCode,
  action_payload: ActionPayload
): SubmitUserActionPromise {
  return await makeAuthorizedPostRequestToBackend({
    url: makeUrl("user_actions"),
    data: { action_code, action_payload },
    axiosConfig: undefined,
  });
}
