import React, { useState } from "react";
import { Tab, Tabs } from "@blueprintjs/core";
import { SentCampaignEmailsTable } from "../../entities/sent-campaign-emails/sentCampaignEmails-entity";
import styled from "styled-components/macro";
import { IncrementalPurchaseItemsTable } from "../../entities/incremental_purchase_items/incrementalPurchaseItems-entity";

export function TableTabsUnit({ fullScreenEnabled = true, ...props }) {
  const defaultTabId = TAB_IDS.incrementalOrders;
  const [selectedTabId, setSelectedTabId] = useState(defaultTabId);
  const handleTabChange = (newTabId, prevTabId, event) => {
    setSelectedTabId(newTabId);
  };
  return (
    <TabsContainer>
      <StyledTabs
        onChange={handleTabChange}
        selectedTabId={selectedTabId}
        large
        fill
        renderActiveTabPanelOnly={true}
      >
        <Tab
          id={TAB_IDS.incrementalOrders}
          title="Incremental Orders"
          panel={<IncrementalPurchaseItemsTable />}
        />
        <Tab
          id={TAB_IDS.cgnEmails}
          title="Sent Campaign Emails (latest 100,000)"
          panel={<SentCampaignEmailsTable />}
        />
      </StyledTabs>
    </TabsContainer>
  );
}

const TAB_IDS = {
  incrementalOrders: "incremental-orders",
  cgnEmails: "cgn-emails",
};

const StyledTabs = styled(Tabs)`
  margin-top: -15px;
`;
const TabsContainer = styled.div`
  width: 100%;
  max-height: 100vh;
`;
