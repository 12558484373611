import { TableModel, tableModelFactory, TableRow } from "./table-model-factory";
import {
  Actions,
  Computed,
  computed,
  thunk,
  Thunk,
  ThunkOn,
  thunkOn,
} from "easy-peasy";
import { StoreModel } from "./model";
import { timeit } from "../helpers/timing";
import {
  declareEmailSender,
  EmailSender,
} from "../email_senders/user-actions/declare-email-sender";
import { toaster } from "../toaster";
import { Injections } from "./store-injections";
import { FieldName2Value } from "./model-drawer-form";
import { parsePayloadToEmailSender } from "../email_senders/common/parse-object-values";

export interface EmailSendersModel extends TableModel {
  rowData: Computed<EmailSendersModel, TableRow[], StoreModel>;
  rowDataByUUID: Computed<
    EmailSendersModel,
    { [key: string]: TableRow },
    StoreModel
  >;
  handleEditEmailSender: Thunk<
    EmailSendersModel,
    FieldName2Value,
    Injections,
    StoreModel
  >;
  handleDeclareEmailSender: Thunk<
    EmailSendersModel,
    FieldName2Value,
    Injections,
    StoreModel
  >;
  onSourceDataChange: ThunkOn<EmailSendersModel, Injections, StoreModel>;
}

export function getEmailSendersModel(): EmailSendersModel {
  return {
    ...tableModelFactory("emailSenders", "email_senders", (row) => row.uuid),
    rowData: computed(
      [(state) => state.initialData],
      timeit((rowData) => rowData, "store.emailSenders.rowData")
    ),
    rowDataByUUID: computed([(s) => s.rowData], (rowData) =>
      Object.fromEntries(rowData.map((row) => [row.uuid, row]))
    ),
    handleDeclareEmailSender: thunk(
      async (
        actions: Actions<EmailSendersModel>,
        payload: FieldName2Value,
        { getState, dispatch }
      ) => {
        const state = getState();
        const existingEmails = state.initialData.map((row) =>
          row.email_address.toLowerCase()
        );

        if (existingEmails.includes(payload.email_address.toLowerCase())) {
          toaster.warning(
            `Failed to create new Sender Email -- a Sender Email with the email address ${payload.email_address} already exists.`
          );
          throw Error();
        }

        const declaredEmailSender: EmailSender =
          parsePayloadToEmailSender(payload);

        try {
          await declareEmailSender(declaredEmailSender);
          toaster.success(
            `Successfully created new Sender Email ${payload.email_address}`,
            2
          );
          // @ts-ignore
          actions.handleFetchInitialData();
        } catch (e) {
          toaster.warning(
            `Failed to create new Sender Email ${payload.email_address} -- Please try again`,
            2
          );
          throw Error();
        }
      }
    ),
    handleEditEmailSender: thunk(
      async (
        actions: Actions<EmailSendersModel>,
        payload: FieldName2Value,
        { getState, dispatch }
      ) => {
        const state = getState();
        const existingEmails = state.initialData
          .filter((row) => row.uuid !== payload.uuid)
          .map((row) => row.email_address.toLowerCase());

        if (existingEmails.includes(payload.email_address.toLowerCase())) {
          toaster.warning(
            `Failed to create new Sender Email -- a Sender Email with the email address ${payload.email_address} already exists.`
          );
          throw Error();
        }

        const declaredEmailSender: EmailSender =
          parsePayloadToEmailSender(payload);

        try {
          await declareEmailSender(declaredEmailSender);
          toaster.success(
            `Successful edition of Sender Email ${declaredEmailSender.email_address}`,
            2
          );
          // @ts-ignore
          actions.handleFetchInitialData();
        } catch (e) {
          toaster.warning(
            `Failed to edit Sender Email ${declaredEmailSender.email_address} -- Please try again`,
            2
          );
          throw Error();
        }
      }
    ),
    onSourceDataChange: thunkOn(
      (actions, storeActions) => [actions.handleDeclareEmailSender],
      async (actions, target, { getStoreState }) => {
        // @ts-ignore
        await actions.handleFetchInitialData();
      }
    ),
  };
}
